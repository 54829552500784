import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Her Zaman Parıldayın ve Parlayın
			</title>
			<meta name={"description"} content={"CrystalClean Car Wash'da, basit bir temizliğin ötesine geçen olağanüstü bir araba yıkama deneyimi sunmaktan gurur duyuyoruz."} />
			<meta property={"og:title"} content={"Her Zaman Parıldayın ve Parlayın"} />
			<meta property={"og:description"} content={"CrystalClean Car Wash'da, basit bir temizliğin ötesine geçen olağanüstü bir araba yıkama deneyimi sunmaktan gurur duyuyoruz."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-3.jpg?v=2024-06-07T06:52:09.847Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="linkBox" />
			<Override slot="box4" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-8">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 0 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 15px 0px"
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
					sm-font="normal 600 28px/1.2 --fontFamily-sans"
					sm-text-align="left"
					sm-width="100%"
				>
					Kapsamlı Hizmet Menümüz
				</Text>
			</Box>
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 50px 0px"
				lg-flex-direction="column"
				font="--base"
			>
				<Text
					margin="0px 0px 15px 0px"
					font="--base"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
					sm-font="normal 600 28px/1.2 --fontFamily-sans"
					sm-text-align="left"
					sm-width="100%"
				>
					CrystalClean Car Wash'da, tüm araç bakımı ihtiyaçlarınızı karşılamak üzere tasarlanmış geniş bir hizmet yelpazesi sunmaktan gurur duyuyoruz. Temel yıkamalardan kapsamlı detaylandırma paketlerine kadar amacımız aracınızın kesinlikle en iyi görünmesini sağlamaktır. Son teknolojiye sahip tesisimiz ve deneyimli ekibimiz her zaman birinci sınıf sonuçları garanti eder.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06:52:09.831Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Temel Yıkama Paketleri:
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							{" "}Hızlı ve etkili bir temizlik arayanlar için temel yıkama paketlerimiz mükemmeldir.{" "}
							<br />
							Onlar içerir:
Dış Yıkama: Kir ve kirleri gidermek için yüksek kaliteli ürünler kullanılarak yapılan kapsamlı dış temizlik.
							<br />
							{"\n"}İç Mekanın Süpürülmesi: Arabanızın içinin düzenli kalması için iç mekanın hızlı bir şekilde süpürülmesi.{"\n"}
							<br />
							Lastik Temizliği: Fren tozunu ve yol kirini gidermek için ayrıntılı lastik temizliği.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06:52:09.824Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Deluxe Yıkama Paketleri
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Deluxe paketlerimiz daha kapsamlı bir temizlik sunar.{" "}
							<br />
							Onlar içerir:
Elde Yıkama: Kapsamlı ve dikkatli bir temizlik için hassas elde yıkama.{"\n"}
							<br />
							Cam Uygulaması: Aracınızın parlaklığını korumak ve boyasını korumak için uygulanan koruyucu cila.{"\n"}
							<br />
							Gösterge Paneli ve Konsol Temizliği: Lekesiz bir iç mekan için gösterge paneli ve konsolun detaylı temizliği.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06:52:09.815Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Detaylandırma Hizmetleri
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Titiz ve ayrıntılı bir temizlik arayanlar için detaylandırma hizmetlerimiz idealdir. Onlar içerir:{"\n"}
							<br />
							Boya Düzeltme: Çizikleri ve girdap izlerini gidererek aracınıza orijinal parlaklığını geri kazandıran profesyonel boya düzeltme.{"\n"}
							<br />
							İç Detaylandırma: Koltuklar, halılar ve döşemeler dahil olmak üzere iç mekanın derinlemesine temizliği.{"\n"}
							<br />
							Motor Temizliği: Yağ ve kiri gidermek için ayrıntılı motor temizliği, motorunuzun çalıştığı kadar iyi görünmesini de sağlar.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06:52:09.814Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Özel Hizmetler
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Özel ihtiyaçları karşılamak üzere tasarlanmış özel hizmetlerimiz, hedefe yönelik bakım sunar.{" "}
							<br />
							Onlar içerir:
Far Restorasyonu: Daha iyi görünürlük ve parlak bir görünüm için bulanık ve soluk farların onarılması.{"\n"}
							<br />
							Seramik Kaplama: Uzun süreli koruma ve geliştirilmiş parlaklık için gelişmiş seramik kaplama.{"\n"}
							<br />
							Koku Giderme: Arabanızın taze kokmasını sağlamak için etkili koku giderme işlemleri.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-1.jpg?v=2024-06-07T06:52:09.805Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Üyelik Planları
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Düzenli araç bakımı için üyelik planlarımız büyük değer sunar.{" "}
							<br />
							Onlar içerir:
Sınırsız Yıkama: Aylık belirli bir ücret karşılığında sınırsız temel yıkamanın keyfini çıkarın.
							<br />
							{"\n"}İndirimli Detaylandırma: Üye olarak detaylandırma hizmetlerinde indirim kazanın.
Öncelikli Hizmet: Üyeler, zamandan tasarruf etmek için öncelikli hizmetten yararlanabilirler.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-1.jpg?v=2024-06-07T06:52:09.809Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							CrystalClean Car Wash'da temiz bir arabadan daha fazlasını sağlamaya inanıyoruz. Sizi tamamen memnun bırakacak bir deneyim sunmayı hedefliyoruz. Kapsamlı hizmet yelpazemiz, aracınızın mümkün olan en iyi bakımı almasını sağlar.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-5" lg-padding="2rem 0 1rem 0">
			<Override
				slot="SectionContent"
				padding="0px 20% 0px 0px"
				lg-padding="0px 30% 0px 0px"
				md-padding="0px 0 0px 0px"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0 40px 0px"
				font="normal 600 72px/1.2 --fontFamily-sans"
				color="#090e28"
				letter-spacing="4px"
				md-font="normal 600 56px/1.2 --fontFamily-sans"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Daha Fazla Bilgi İçin Bize Ulaşın
				<br />
				+905373080025
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#334455" sm-margin="0px 0px 25px 0px">
				Ve bu sadece başlangıç. İletişime geçerek tüm teklifleri keşfedin. Arabanız en iyisini hak ediyor ve biz de tam da bunu sağlamak için buradayız! Hizmetlerimiz hakkında daha fazla bilgi edinmek mi istiyorsunuz veya randevu almaya hazır mısınız? CrystalClean farkını kendiniz deneyimlemek için bugün bizimle iletişime geçin veya konumumuzu ziyaret edin. Güler yüzlü personelimiz her türlü soru veya özel istekleriniz konusunda size yardımcı olmak için her zaman buradadır.
			</Text>
		</Section>
		<Components.Header>
			<Override slot="link3">
				+905373080025
			</Override>
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});